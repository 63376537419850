.chatbot-container {
  position: fixed;
  bottom: 0;
  right: 20px;
  height: 60vh; /* Set height to 100% of the viewport height */
  border-radius: 15px; /* Adds rounded corners */
  transition: transform 0.3s ease;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  background-color: white;
}

.chatbot-container.closed {
  transform: translateY(calc(100% - 40px)); /* Show only the header when minimized */
  width: 30vh;
}
.chatbot-container.open {
  transform: translateY(0);
  width: 60vh;
}
.chatbot-minimizer {
  font-size: 30px;
  display: flex;
  position: relative;
  left: 10vw;
  top: -15px;
}
.chatbot-header {
  background-color: #88b0d8;
  color: white;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 5px; /* Matches the top corners with the container */
  border-top-right-radius: 5px; /* Matches the top corners with the container */
}

.chatbot-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  border-top: 1px solid #ddd;
  display: flex;
  flex-direction: column;
}

.chatbot-message {
  padding: 8px;
  margin: 5px 0;
  border-radius: 10px;
  max-width: 80%;
  word-wrap: break-word;
}

.chatbot-message.user {
  background-color: #d1e7ff;
  align-self: flex-end;
  color: 'black';
}

.chatbot-message.assistant {
  background-color: #f1f0f0;
  align-self: flex-start;
}

.input-textarea {
  flex: 1;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  resize: none;
  overflow-y: hidden;
  width: 100%;
  box-sizing: border-box;
  min-height: 35px;
  max-height: 100px;
  margin-bottom: 5px;
}

.chatbot-input button {
  padding: 8px 10px;
  margin-left: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.chatbot-input button:hover {
  background-color: #0056b3;
}

.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
}

.dot {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  border-radius: 50%;
  background-color: #007bff;
  animation: blink 1.4s infinite both;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes blink {
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

@media print {
  .chatbot-container {
    display: none;
  }
}


