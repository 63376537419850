.app-header {
    background-color: #1f2f2c;
    color: #333;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 3px solid #87b6a7;
    position: relative;
    z-index: 100;
}
.school-selection {
    display: flex;
    align-items: center;
    width: auto;
    border-radius: 4px;
}

.school-selection select {
    padding: 5px;
    font-size: 16px;
    border-radius: 4px;
}
.school-selection label {
    display: none;
}
.logo {
    width: 150px;
}

.school-selection label {
    display: block;
    margin-left: 12px;
    margin-right: 4px;
    font-weight: bold;
    border-radius: 4px;
    color: #ccc; /* Adjust color as needed */
}

.settings-button {
    width: 20px;
    cursor: pointer; 
    padding: 10px;
}

.settings-dropdown {
    position: absolute;
    top: 100%;  /* Position below the settings button */
    right: 20px;
    border: 1px solid #ccc;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
  }
  
  .settings-dropdown button {
    background: none;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .settings-dropdown button:hover {
    background-color: #f0f0f0;
  }



@media print {
    .app-header {
        display: none !important;
    }
  }