/* Container and General Styling */
.container {
  width: 100%;
  max-width: 900px; /* Slightly wider */
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.header {
  text-align: center;
  font-size: 1.5em;
  color: #333;
  margin-bottom: 20px;
}

.cardPeer-container {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.cardPeer {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  min-width: 250px; /* Slightly wider */
  min-height: 300px
}

/* Input Fields */
.dropdown {
  width: 93.5%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Input Fields */
.input {
  width: 85%;
  padding: 10px;
  margin: 5px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Buttons */
.btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.add {
  background: #4caf50;
  color: white;
}

.remove {
  background: #f44336;
  color: white;
  padding: 2px 4px;
}

.save {
  background: #2196f3;
  color: white;
}

/* Suggestions and Selected Peers */
.suggestions-list-peers {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  max-height: 150px;
  overflow-y: auto;
}

.selected-list {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
  max-height: 250px; /* Height for up to five peers */
  overflow-y: auto;
}

.suggestions-list-peers li, .selected-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 5px 0;
  background: white;
}

.suggestions-list li:hover, .selected-item:hover {
  background: #e0f7fa;
}

/* Message Styling */
.message {
  text-align: center;
  color: #4caf50;
  margin-top: 15px;
  font-weight: bold;
}

.button-container {
  display: flex;
  gap: 10px; /* Adjust spacing between buttons as needed */
  justify-content: left; /* Center the buttons horizontally within the container */
  margin-top: 10px; /* Add some top margin if needed */
}

.button-container .btn.save,
.button-container .btn.delete {
  height: 40px; /* Match the height for both buttons */
  margin-left: 1.5%;
}