.budget-dashboard {
  padding: 20px;
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
  transition: ease;
  height: 220vh;
}
.dashboard-header {
  font-size: 30px;
  font-weight: bold;
  color: #2b343d;
  padding: 10px;
}
.dashboard-cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.title {
  font-size: 35px;
  margin-bottom: 20px;
  color: #2b343d;
  text-align: left;
  font-weight: bold;
  padding: 10px;
}

.drilldownTableContainer {
  width: 130vh;
  height: 30vh;
  margin-bottom: 50px;
  margin-left: 50px;
}

/* Main container for all toggle groups */
.toggle-container-budget-dash {
  display: flex;
  justify-content: flex-start;
  max-width: 1000px;
  padding: 10px;
  background-color: transparent; /* Ensure no background interference */
  overflow: visible !important; /* Allow box shadows */
  gap: 0; /* Remove gap for manual margins */
}

/* General column styling for each filter group */
.column-budget-dash {
  width: 300px;
  padding: 15px;
  margin: 30px; /* Use margin for spacing */
  z-index: 1; /* Ensure it appears above other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); /*Ensure shadow is applied */
  border-radius: 8px;
}

.column-budget-dash h3 {
  margin-bottom: 10px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

/* Per Student toggle */
.per-student-toggle-budget-dash label {
  display: flex;
  align-items: center; /* Align checkbox with text */
  font-size: 14px;
  cursor: pointer;
}

/* Fund toggles - stack checkboxes vertically */
.fund-toggles-budget-dash label {
  display: block; /* Each checkbox on its own line */
  margin-bottom: 8px; /* Add spacing between checkboxes */
  font-size: 14px;
  cursor: pointer;
}

/* Object toggles - grid layout with two columns */
.object-values-budget-dash {
  display: grid; /* Use grid for layout */
  grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
  gap: 8px 15px; /* Vertical and horizontal spacing */
  padding: 5px 0; /* Padding for content spacing */
}

.object-values-budget-dash label {
  display: flex;
  align-items: center; /* Align checkbox with text */
  font-size: 14px;
  white-space: nowrap; /* Prevent text wrapping */
  cursor: pointer;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 768px) {
  .toggle-container-budget-dash {
    flex-direction: column; /* Stack toggle boxes vertically */
    gap: 15px; /* Adjust spacing */
  }

  .column-budget-dash {
    width: 100%; /* Full width on small screens */
  }
}
