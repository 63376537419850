/* CSS */
.card-local-revenue-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px;
}

.bottom-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0px;
}

.card-local-revenue {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.assessed-values-container {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  gap: 15px;
}

.assessed-value-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.classroom-trust-container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  gap: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 200px;
}

.summer-school-container {
  display: flex;
  gap: 10px; /* Space between the input fields */
}

.state-revenue-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 1045px;
}

.state-revenue-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

.state-revenue-table th,
.state-revenue-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.state-revenue-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.state-revenue-table input {
  width: 100%;
  border: none;
  text-align: center;
}


.state-revenue-column {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.local-property-tax-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.vocation-tuition-section {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.card-local-revenue h3 {
  margin: 0;
  color: #333;
}

.card-local-revenue input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.total-local-revenue {
  margin-top: 10px;
  font-weight: bold;
  color: #2196f3;
  text-align: right;
}

/* CSS for Baseline Salary Grant Component */
.baseline-salary-grant-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 250px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensure consistent width */
  align-items: flex-start; /* Align content to the left */
}

.baseline-salary-grant-container h3 {
  margin-bottom: 10px;
  color: #333;
}

.project-code-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.project-code-section label {
  font-weight: bold;
  color: #555;
}

.baseline-salary-grant-container button {
  padding: 10px 15px;
  background: #2196f3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.baseline-salary-grant-container button:hover {
  background: #1769aa;
}

.total-baseline-grant {
  font-weight: bold;
  color: #333;
  font-size: 16px;
}
