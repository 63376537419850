.sidebar {
  background-color: #f2f2f2;
  transition: width 0.3s cubic-bezier(0.4, 0, 0.2, 1), padding 0.3s ease-in-out;
  display: flex;
  width: 13%;
  flex-direction: column;
  white-space: nowrap;
}

.sidebar.minimized {
  width: 40px;
  transition: ease;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 10px;
  border-bottom: 1px solid #dddddd;
  font-size: 16px;
  cursor: pointer;
}

.sidebar li.active {
  background: #ffffff7c;
  font-weight: bold;
  transition: ease;
  transition-duration: .5s;
  position: relative;
  z-index: 10;
}

.sidebar li:hover {
  font-weight: bold;
  transition: ease;
  }

.toggle-arrow {
  position: relative;
  top: 10px;
  left: -5px;
  text-align: right;  
  color: #333;
  padding: 5px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 600;
  transition: ease;
  transition-duration: .5s;
}

.category-title {
    font-weight: bold;
    margin-top: 15px;
    cursor: pointer;
    color: #333; /* Adjust as needed */
}

.sub-category {
  margin-left: 20px; /* Indent sub-categories for clearer hierarchy */
  color: #555; /* Optional: lighter color for sub-categories */
}

@media print {
  .sidebar {
      display: none !important;
  }
}
