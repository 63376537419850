.card {
  padding: 20px;
  border-radius: 10px;
  color: #000;
  margin: 10px;
  width: 43%;
  height: 20vh;
  cursor: pointer;
  position: relative;
  transition: height 0.5s ease, width 0.5s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  overflow: visible;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  resize: both;
}

.card.expanded {
  transition: height 0.5s ease, width 0.5s ease;
  height: 72vh;
  width: 85%;
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  top: 100%; /* Tooltip appears below the icon */
  left: 50%;
  transform: translateX(-50%);
  white-space: normal; /* Allow wrapping for long text */
  z-index: 1000; /* Ensure it appears above other elements */
  visibility: hidden; /* Initially hidden */
  opacity: 0;
  transition: opacity 0.2s;
  width: max-content; /* Allow the tooltip to expand based on the text size */
  max-width: 250px; /* Set a max width to avoid it being too wide */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Optional: add a shadow for better visibility */
}

/* On-hover effect */
.card:hover {
  background-color: #e0e0e0; /* Slightly darker background color */
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Increase shadow */
}
.card:hover .tooltip {
  opacity: 1;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.card-title {
  font-size: 35px;
  font-weight: bold;
}

.card-small-text {
  margin-top: -10px;
  margin-right: -10px;
  font-size: 15px;
  color: #555;
}

.card-summary-text {
  margin-top: 0px;
  font-size: 20px;
  color: #555;
}

.card-value {
  font-size: 50px;
  margin-top: 30px;
  transition: margin-top 0.5s ease
}

.card-value.inline {
  /*
  display: inline-block;
  margin-top: 0;
  margin-left: 10px;
  */
  margin-top: 5px;
  transition: margin-top 0.5s ease
}

.card-graph {
  height: 95%;
  width: 100%;
  margin-left: 20px;
  margin-top: 20px;
  flex: 1;
  opacity: 0;
  transition: opacity 1.0s ease
}

.card-graph.visible {
  height: 90%;
  width: 100%;
  margin-left: 20px;
  margin-top: 20px;
  flex: 1;
  opacity: 1;
  transition: opacity 1.0s ease
}

.card-table {
  height: 90%;
  width: 90%;
  margin-left: 20px;
  margin-top: 20px;
  flex: 1;
  opacity: 1;
  transition: opacity 1.0s ease
}

.card-table.visible {
  height: 90%;
  width: 70%;
  margin-left: 20px;
  margin-top: 20px;
  flex: 1;
  opacity: 1;
  transition: opacity 1.0s ease
}

.checkbox-container {
  margin-top: 100px;
  margin-right: 20px;
  display: flex; /* Use flexbox for the checkbox container */
  flex-direction: column; /* Stack the checkboxes vertically */
}

.checkbox-container label {
  display: flex; /* Use flexbox for label */
  align-items: center; /* Center align checkbox and text vertically */
  margin-bottom: 10px; /* Optional: Add space between checkboxes */
}

.checkbox-container input[type="checkbox"] {
  margin-right: 10px; /* Add space between checkbox and label text */
}

.card-graph-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  background-color: rgb(245, 245, 245);
  border-radius: 10px;
  margin-top: 10px;
  margin-left: 0px;
  margin-bottom: 0px;
  height: 0%;
  width: 0%;
  opacity: 0;
  transition: opacity 0.5s ease, height 0.5s ease, width 0.5s ease; /* Fade in effect */
}

.card-graph-container.visible {
  opacity: 1; /* Become fully visible */
  height: 75%;
  width: 90%;
  transition: opacity 0.5s ease, height 0.5s ease, width 0.5s ease; /* Fade in effect */
}

.card-arrow-container {
  position: absolute;
  top: 35px; /* Keep the container fixed near the top */
  right: 30px;
  text-align: center; /* Center the text under the arrow */
}

.card-arrow {
  font-size: 80px;
  margin-bottom: 5px; /* Adjust spacing between arrow and label */
  color: #272727;
}

.card-arrow-label {
  font-size: 16px; 
  color: #272727; 
  margin-top: -22px; /* Move the label closer to the arrow */
}

.card-title {
  display: flex;
  align-items: center;
  position: relative;
}

.info-icon {
  margin-left: 5px;
  font-size: 10px; /* Small font size for the "?" */
  color: #888;
  cursor: pointer;
  border-radius: 50%;
  padding: 1px;
  border: 1px solid #888;
  width: 12px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -5px; /* Move it up by 2px */
  left: -2px;
}


.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 8px;
  border-radius: 4px;
  font-size: 12px;
  top: 100%; /* Tooltip appears below the icon */
  left: 50%;
  transform: translateX(-50%);
  white-space: normal; /* Allow wrapping for long text */
  z-index: 1000; /* Ensure it appears above other elements */
  visibility: hidden; /* Initially hidden */
  opacity: 0;
  transition: opacity 0.2s;
  width: max-content; /* Allow the tooltip to expand based on the text size */
  max-width: 250px; /* Set a max width to avoid it being too wide */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Optional: add a shadow for better visibility */
}

.tooltip.visible {
  visibility: visible; /* Becomes visible when hovered */
  opacity: 1;
}

.tooltip::before {
  content: '';
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #333 transparent; /* Creates a small arrow pointing up to the "?" */
}

.toggle-button {
  position: absolute;
  top: 136px;  /*Keep it at the bottom of the container */
  left: 83%;
  transform: translateX(-50%); /* Center the button horizontally */
  padding: 5px 10px;
  background-color: #3f98f7;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
 
.toggle-button:hover {
  background-color: #0056b3;
}

.rounded-triangle {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 40px solid #000; /* Adjust color as needed */
  border-radius: 10px; /* This will round the edges of the triangle */
}

.rounded-triangle.up {
  transform: rotate(0deg);
}

.rounded-triangle.down {
  transform: rotate(180deg);
}

.rounded-triangle.flat {
  border-left: 20px solid #000;
  border-right: 0;
  border-bottom: 0;
  border-radius: 5px;
}

@media print {
  .card {
    box-shadow: none;
  }
  .info-icon {
    display: none; /* Hide info-icon when printing */
  }
}


