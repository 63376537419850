/* AuthenticationCard.css */
.authentication-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  min-height: 100vh;
  background-color: #eeeeee;
}
.login-logo {
  width: 300px;
  margin-bottom: 5vh;
}
.authentication-card {
  width: auto;
  padding: 40px;
  margin: 0 3%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-radius: 3px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.authentication-header h2 {
  line-height: 1;
  margin: 0;
}
.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin-bottom: 25px;
}

.authentication-button {
  border: 1px solid #cccccc;
  border-radius: 3px;
  padding: 10px 15px;
  background-color: #ffffff;
  display: flex;
  font-size: 16px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.authentication-button:hover {
  background-color: #f3f3f3;
}

.button-icon {
  width: 5vh;
  max-width: 30px;
  height: 5vh;
  max-height: 30px;
  margin-right: 10px;
}

.email-password-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 95%;
  gap: 5px;
}

.email-password-container input {
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.email-password-container button {
  width: 105%;
  margin: 15px 0;;
  justify-content: center;
  padding: 10px 15px;
  background-color: #87b6a7;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.email-password-container button:hover {
  background-color: #73a796;
}

.footer-text {
  font-size: 12px;
  text-align: center;
  color: #8d8d8d;
}

.toggle-auth-mode {
  cursor: pointer; /* Changes the mouse to a pointer */
  text-decoration: none; /* Removes underline by default */
  color: inherit; /* Keeps the text color as it is in the parent */
}

.toggle-auth-mode:hover {
  text-decoration: underline; /* Underlines the text on hover */
}

