.dashboard {
  padding: 20px;
  width: 100%;
  background-color: #f9f9f9;
  box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);
  transition: ease;
  min-height: 100vh; /* Ensures it covers full viewport height but can grow */
  height: auto; /* Makes the height adapt based on content */
  overflow-y: auto; /* Allows scrolling if content exceeds viewport height */
  overflow: visible;
}

.drilldownTableContainer{
  width: 100%;
  max-width: 100%;
}

.dashboard-header {
  font-size: 30px;
  font-weight: bold;
  color: #2b343d;
  padding: 10px;
}

.dashboard-cards {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.title {
  font-size: 35px;
  margin-bottom: 20px;
  color: #2b343d;
  text-align: left;
  font-weight: bold;
  padding: 10px;
}

.key-highlights {
  margin-top: 5%;
  margin-bottom: 40px;
  font-family: Arial, sans-serif;
}

.key-highlights h1 {
  font-size: 35px; /* Increase heading size */
  font-weight: bold;
  margin-bottom: 10px;
}

.key-highlights li {
  font-size: 18px; /* Increase list item text size */
  margin-bottom: 8px;
  line-height: 1.5;
}

.key-highlights ul {
  list-style-type: disc;
  margin: 0;
  padding-left: 20px;
}

/* Printing styles */
@media print {
  .dashboard {
      width: 100vw;
      background-color: transparent;
      box-shadow: none;
      margin: 0;
      margin-top: 30px;
      padding: 0;
  }

  .title, .dashboard-header {
      margin: 0;
      padding: 0;
      font-size: 1.2em;
  }

  .dashboard-cards {
      display: grid;
      grid-template-columns: 1fr 1fr; /* Two columns for 2x2 grid */
      gap: 10px;
      page-break-inside: avoid;
  }

  .card {

      height: auto;
      padding: 15px;
      box-shadow: none;
  }

  /*.card-title, .card-value, .card-summary-text {
      font-size: 0.8em;
  }*/

  .tooltip {
      display: none;
  }

  .year-month-selector{
    display: none !important;
  }

  .fund-toggles {
    display: none !important; /* Hides the toggles during print */
  }

  .drilldownTableContainer{
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 1px !important;
  }

  .graphs-container {
    display: flex !important; /* Align graphs side-by-side */
    justify-content: space-between !important; /* Add equal spacing */
    gap: 10px !important; /* Add a small gap between graphs */
  }

  .graphs-container > div {
    width: 48% !important; /* Ensure each graph fits side-by-side */
    box-sizing: border-box; /* Include padding and borders in width */
  }

  canvas {
    max-width: 100% !important; /* Ensure canvas scales correctly */
    height: auto !important;
  }

  body {
    font-size: 12px !important; /* Adjust font size for better readability */
  }
}
