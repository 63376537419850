.app {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  overflow-x: hidden;
}

.main-component {
  display: flex;
  flex-direction: column;
  width: 87%;
}

.main-component.expanded {
  width: 97%; /* Expands when sidebar is minimized */
}
