.budget-summary-container {
  width: 100%;
  max-width: 100%;
  margin: 2% 0;
  background-color: #f0f8ff;
  border-radius: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-align: center;
}

.budget-summary-table {
  width: 98%;
  border-collapse: separate;  /* ✅ Allow border-radius to work */
  border-spacing: 0;  /* ✅ Removes gaps between cells */
  font-size: 16px;
  background-color: #f0f8ff;
  margin-left: 1%;
  margin-bottom: 2%;
  border-radius: 10px;  /* ✅ Apply rounding */
  overflow: hidden;  /* ✅ Ensures content respects rounded corners */
}

.budget-summary-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
  size: 16px;
}

.budget-summary-table th {
  background-color: #2c3e50;
  font-weight: bold;
  color: white;
  padding: 10px;
}

.budget-summary-table input {
  width: 90%;
  border: none;
  text-align: center;
  font-size: 16px;
}

.budget-summary-table:not(:last-child) {
  margin-bottom: 40px;
}

.sb287-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sb287-modal {
  background: white; /* Ensure modal has a visible background */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 300px;
  z-index: 1100;
}

.sb287-modal h3 {
  margin-top: 0;
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
  color: black
}

.sb287-modal form div {
  margin-bottom: 15px;
}

.sb287-modal form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: black
}

.sb287-modal form input {
  width: 90%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.sb287-modal button {
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.sb287-modal button:last-child {
  background-color: #6c757d;
}

.sb287-modal button:hover {
  opacity: 0.9;
}

