.budget-builder-container {
  width: 92%;
  height: 100%;
  padding: 10px 1.5%; /* Responsive padding */
}

.toggle-arrow {
  cursor: pointer;
  margin-bottom: 10px;
}

.graph-container {
  display: flex;
  justify-content: space-between;
  gap: 10px; /* Optional: space between graphs */
  width: 100%;
  height: 50%;
}

.graphs-container{
  width:100%;
  display: flex;
}
.revenue-expenses-graph-container{
  width: 50%;
  height: 500px;
}
.fund-balance-graph-container{
  width: 50%;
  height: 500px;
}


.budget-builder-table {
  width: 98%;
  border-collapse: separate;  /* ✅ Allow border-radius to work */
  border-spacing: 0;  /* ✅ Removes gaps between cells */
  font-size: 16px;
  background-color: #f0f8ff;
  margin-left: 1%;
  border-radius: 10px;  /* ✅ Apply rounding */
  overflow: hidden;  /* ✅ Ensures content respects rounded corners */
}
