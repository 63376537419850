.staff-management-container {
  font-family: Arial, sans-serif;
  width: 95%;
  overflow-x: auto; /* Enable horizontal scrolling */
  border: none;
  padding: 20px;
}

.staff-nested-container {
  font-family: Arial, sans-serif;
  width: 97%;
  overflow-x: auto; /* Enable horizontal scrolling */
  border: none;
  padding: 20px;
}

.staff-table-container {
  margin: 10px;
  font-family: Arial, sans-serif;
  width: 98%;
  overflow-x: auto; /* Enable horizontal scrolling */
  border: 1px solid #cddbe3; /* Subtle border to match card theme */
  border-radius: 8px; /* Rounded corners for a modern card-like feel */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Card shadow */
  padding: 0px;
  max-height: 500px; /* Set the max height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.certified-staff-table-container {
  margin: 10px;
  font-family: Arial, sans-serif;
  width: 98%;
  overflow-x: auto; /* Enable horizontal scrolling */
  border: 1px solid #cddbe3; /* Subtle border to match card theme */
  border-radius: 8px; /* Rounded corners for a modern card-like feel */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Card shadow */
  padding: 0px;
  max-height: 500px; /* Set the max height */
  overflow-y: auto; /* Enable vertical scrolling */
}

.details-btn {
  margin-top: 5px;
}

.benefits-checkboxes {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align items to the start of the container */
  align-items: flex-start; /* Align items to the left */
}
.add-row-btn {
  background-color: #3182ce; /* BalanceHQ button blue */
  color: white;
  padding: 8px 12px; /* Slightly larger for better usability */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  margin-right: 5px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.add-row-btn:hover {
  background-color: #2769a5; /* Darker blue for hover */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Subtle hover shadow */
}

.staff-management-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px; /* Rounded corners */
  overflow-x: auto;
  border: 1px solid #cddbe3; /* Match container border */
  background-color: #ffffff; /* White background for contrast */
  margin-bottom: 3px;
  margin: 5px;
  box-shadow: 0 0 4px rgba(49, 130, 206, 0.5);
}

.certified-staff-table {
  width: 99%;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 8px; /* Rounded corners */
  overflow-x: auto;
  border: 1px solid #cddbe3; /* Match container border */
  background-color: #ffffff; /* White background for contrast */
  margin-bottom: 3px;
  margin: 5px;
  box-shadow: 0 0 4px rgba(49, 130, 206, 0.5);
}


.staff-management-table th,
.staff-management-table td {
  border: 1px solid #cddbe3; /* Subtle border for cells */
  padding: 10px; /* Slightly larger padding for better readability */
  text-align: center;
  font-size: 14px;
  color: #4a5568; /* BalanceHQ text color */
}

.staff-management-table th {
  background-color: #c9eafc; /* Slightly darker blue for header */
  font-weight: bold;
  text-transform: uppercase; /* Optional for header emphasis */
  font-size: 14px;
}

.staff-management-table tr:nth-child(even) {
  background-color: #f6fbff; /* Soft blue for striped rows */
}

.staff-management-table tr:hover {
  background-color: #edf8ff; /* Slightly darker blue on hover */
}

.staff-management-table input[type="text"],
.staff-management-table input[type="number"],
.staff-management-table select {
  width: 95%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #cddbe3; /* Match table borders */
  border-radius: 4px;
  font-size: 14px;
  color: #4a5568;
  background-color: #ffffff; /* White input background */
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  min-width: 65px;
}

  .inputs-card {
    background-color: #e7f4fc; /* BalanceHQ background */
    border: 1px solid #cddbe3; /* Subtle border */
    border-radius: 8px; /* Rounded corners for a modern look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Card shadow */
    padding: 20px;
    margin-bottom: 20px;
    width: 50%
  }
  
  .inputs-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Responsive grid */
    gap: 20px;
  }
  
  .inputs-container div {
    display: flex;
    flex-direction: column; /* Stack label and input vertically */
  }
  
  .inputs-container label {
    font-size: 14px; /* Improve readability */
    font-weight: bold;
    color: #4a5568; /* Darker text for better contrast */
    margin-bottom: 5px;
  }
  
  .inputs-container input {
    padding: 8px;
    border: 1px solid #cddbe3; /* Match card border color */
    border-radius: 4px;
    font-size: 14px;
    color: #4a5568;
    text-align: right;
    background-color: #ffffff; /* Ensure inputs stand out */
    transition: border-color 0.2s ease, box-shadow 0.2s ease;
  }
  
  .inputs-container input:focus {
    border-color: #3182ce; /* Highlight on focus */
    box-shadow: 0 0 4px rgba(49, 130, 206, 0.5); /* Subtle focus effect */
  }

  .tableInput {
    margin-left: 3px;
    margin-right: 3px;
  }

  
  /* Modal container styles */
.modal {
  position: fixed; /* Fixed position to overlay the screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it overlays other elements */
}

/* Modal content styles */
.modal-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
}

/* Modal title */
.modal-content h4 {
  margin: 0 0 10px 0;
}

/* Modal actions (buttons) */
.modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.modal-actions button {
  margin-left: 10px;
}

.notification {
  position: fixed;
  top: 5%;
  right: 45%;
  background-color: #4caf50; /* Green for success */
  color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.benefit-values-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.benefit-values-actions button {
  margin-left: 10px;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
}


/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}