.graph-legend {
    border-radius: 10px;
    background-color: rgb(241, 240, 240);
    margin: 10px 0px 0px -10px;
    height: 400px;
  }

  .group-selection {
    display: flex;
    align-items: center;
    width: auto;
    border-radius: 4px;
}

.group-selection select {
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
}
.group-selection label {
  display: none;
}