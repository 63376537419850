.table-container {
    max-height: 300px; /* Set a fixed height for the table container */
    overflow-y: auto; /* Enable vertical scrolling when the content overflows */
    border: 1px solid #ddd; /* Optional: Add a border for styling */
  }
  
  .generic-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .generic-table th, td {
    padding: 8px;
    border-bottom: 1px solid #ddd;
  }
  
  .generic-table thead {
    background-color: #f9f9f9; /* Optional: Add a background color for the header */
    position: sticky;
    top: 0; /* Sticky header to keep the header in view while scrolling */
  }
  