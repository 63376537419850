.classified-staff-container {
  width: 97%;
  overflow-x: auto;
  max-height: 80vh;
  overflow-y: auto;
}

.classified-staff-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #e7f4fc;
  border-radius: 8px;
}

.classified-staff-table th {
  position: sticky;
  top: 0; /* Sticky header to keep the header in view while scrolling */
  z-index: 3;
  background-color: #e7f4fc;
}

.classified-staff-table thead tr:nth-child(1) th {
  top: -20px; /* Example: Offset for the first header row */
}

.classified-staff-table thead tr:nth-child(2) th {
  top: 180px; /* Example: Offset for the second header row */
}
