.high-level-table-container {
  /* border-collapse: collapse; */
  width: 100%; /* Make the table wider */
  overflow: hidden;
  font-size: 16px; /* Enlarge table text for readability */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5); /* Add shadow effect */
  background-color: rgb(240, 248, 255); /* #eee; */
  margin-bottom: 3.5%;
  margin-top: 0%;
  text-align: center;
  border-radius: 10px;
}

.high-level-table{
  border-radius: 10px;
  border-collapse: collapse;
  width: 98%;
  text-align: left;
  margin-left: 1%;
  margin-bottom: 2%;
  overflow: hidden;
}

.high-level-table th {
  /* border-collapse: collapse; */
  background-color: #2c3e50;
  color: white;
  padding: 10px;
  text-align: center;
}

.high-level-table tr {
  position: relative;
}

.add-row-modal {
  position: fixed; /* Fixed position to overlay the screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it overlays other elements */
}

/* Modal content styles */
.add-row-modal-content {
  background-color: #fff;
  border-radius: 12px;
  padding: 25px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  gap: 5px; /* Adds spacing between rows */
  z-index: 1001; /* Ensure it overlays other elements */
}

.add-row-modal-content label {
  position: relative;
  font-size: 20px; /* Adjust font size as needed */
  margin-left: 40px;
}

.add-row-modal-content input,
.add-row-modal-content select {
  flex: 1; /* Ensures inputs take up available space */
  margin-left: 10px; /* Adds spacing between label and input */
  padding: 5px; /* Adds padding inside inputs for better appearance */
  font-size: 18px;
  max-width: 250px;
}

/* Modal title */
.add-row-modal-content h4 {
  margin-left: 80px;
  margin-top: 0px;
  font-size: 20px;
}

/* Modal actions (buttons) */
.add-row-modal-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  
}

.add-row-modal-actions button {
  margin-left: 10px;
  font-size: 16px;
  padding: 10px;
}

label {
  display: block;
  margin-bottom: 8px; /* Optional: Add spacing between label and input */
}

.line-item-table-container {
  max-height: 500px; /* Set the maximum height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Add a border to visually separate the container */
  border-radius: 10px;
}

.line-item-table {
  width: 100%;
  margin: 0px 0;
  border-collapse: collapse;
}

.line-item-table th {
  border: 1px solid black;
  border-top: 0px solid black; /* needed */
  padding: 10px;
  text-align: center;
  background-color: #2c3e50;
  color: white;
  position: sticky;
  top: 0; /* Sticky header to keep the header in view while scrolling */
  z-index: 2;
}

.line-item-table td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: center;
}

.line-item-table input {
  width: 100%;
  border: none;
  text-align: center;
}

.line-item-graph-container {
  margin: 0px;
}

.suggestions-list {
  position: absolute; /* Position the suggestions relative to the parent container */
  top: 100%; /* Place the list directly below the input box */
  left: 0; /* Align the list with the left side of the input */
  width: 100%; /* Match the width of the input box */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove default padding */
  list-style: none; /* Remove bullets from the list */
  background-color: white; /* Make the background of the suggestions white */
  border: 1px solid #ccc; /* Add a border to separate the suggestions from the input */
  border-radius: 4px; /* Slightly round the corners */
  z-index: 10; /* Ensure the suggestions appear above other elements */
  max-height: 300px; /* Set a maximum height for the list */
  overflow-y: auto; /* Allow scrolling if there are many suggestions */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
}

.suggestions-list li {
  padding: 8px 12px; /* Add padding inside each suggestion */
  cursor: pointer; /* Change the cursor to indicate clickability */
}

.suggestions-list li:hover {
  background-color: #f0f0f0; /* Highlight the suggestion on hover */
}