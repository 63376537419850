/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: 'Arial', sans-serif;
  }
  
  .loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: linear-gradient(135deg, #1f2f2c, #87b6a7);
    color: #ffffff;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transition: transform 1s ease, opacity 1s ease;
    z-index: 10;
  }
  
  .loading-container.exit {
    transform: translateY(-100%); /* Slide up */
    opacity: 0; /* Fade out */
  }

  /* Logo Styling */
  .logo-container {
    margin-bottom: 20px;
  }
  
  .logo-loading {
    width: 400px; /* Set the width to make the logo bigger */
    height: auto; /* Maintain the aspect ratio of the logo */
    animation: fadeIn 1.5s ease-in-out infinite alternate; /* Keep the fade-in animation */
  }
  
  /* Spinner Styling */
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #ffffff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* Keyframes for Animation */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }
  